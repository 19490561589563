import React, { useState, useEffect } from 'react';
import '../assets/css/style.css';

const Header = () => {
  const [isDarkMode, setIsDarkMode] = useState(false);
  const [isMenuActive, setIsMenuActive] = useState(false);

  const handleDarkModeToggle = () => {
    setIsDarkMode(!isDarkMode);
    document.body.classList.toggle("dark-mode");
  };

  const handleMenuToggle = () => {
    setIsMenuActive(!isMenuActive);
  };

  useEffect(() => {
    const handleScroll = () => {
      const headerNavbar = document.querySelector(".navbar");
      const sticky = headerNavbar.offsetTop;

      if (window.pageYOffset > sticky) {
        headerNavbar.classList.add("sticky");
      } else {
        headerNavbar.classList.remove("sticky");
      }

      const backToTop = document.querySelector(".scroll-top");
      if (document.body.scrollTop > 50 || document.documentElement.scrollTop > 50) {
        backToTop.style.display = "flex";
      } else {
        backToTop.style.display = "none";
      }
    };

    window.addEventListener("scroll", handleScroll);
    
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <header className="header-area">    
      <header className={`header-wrap'}`}>
        <nav>
          <nav className="navbar">
            <div className="logo">
              <a href="#">
                <img src="/images/logo.png" alt="" />
              </a>
            </div>
            <i className="lni lni-menu" onClick={handleMenuToggle}></i>
            <div className={`nav-links ${isMenuActive ? 'active' : ''}`}>
              <ul className="links">
                <li><a className="link" href="#home">Home</a></li>
                <li><a className="link" href="#about">About</a></li>
                <li><a className="link" href="#portfolio">Portfolio</a></li>
                <li><a className="link" href="#service">Services</a></li>
                <li><a className="link" href="#project">Projects</a></li>
                <li><a className="link" href="#contact">Contact</a></li>
                <li className="mode">
                  <span className="mr-2 text-warning light-mode" style={{ display: isDarkMode ? 'none' : 'inline' }}>
                    <i className="lni lni-sun"></i> Light Mode
                  </span>
                  <span className="mr-2 text-warning night-mode" style={{ display: isDarkMode ? 'inline' : 'none' }}>
                    <i className="lni lni-night"></i> Dark Mode
                  </span>
                  <input type="checkbox" id="dark-mode" checked={isDarkMode} onChange={handleDarkModeToggle} />
                  <label htmlFor="dark-mode"></label>
                </li>
              </ul>
            </div>
          </nav>
        </nav>
      </header>
      <div id="home" className="header-hero bg_cover d-lg-flex align-items-center">
        <div className="shape shape-1"></div>
        <div className="shape shape-3"></div>
        <div className="shape shape-4"></div>
        <div className="shape shape-5"></div>
        <div className="shape shape-6"></div>
        <div className="container">
          <div className="row align-items-center justify-content-center justify-content-lg-between">
            <div className="col-lg-6 col-md-10">
              <div className="header-hero-content">
                <h3 className="header-title wow fadeInLeftBig" data-wow-duration="1.3s" data-wow-delay="1.2s"><span>Hello, I am</span> Alvin Alvito</h3>
                <p className="text wow fadeInLeftBig" data-wow-duration="1.3s" data-wow-delay="1.6s"> Welcome to my portfolio website! My name is Paris Alvito you can call me Alvin Alvito, I am a Programmer & I like creating innovative work that connects ideas and reality. Let's start something amazing together!</p>
                <a target="_blank" href="https://www.instagram.com/av_into?igsh=MTkydndxYXY2ZGFnMw==" rel="nofollow" className="main-btn mr-2 wow fadeInLeftBig" data-wow-duration="1.3s" data-wow-delay="1.8s"><span className="h6 text-light mr-2"><i className="lni lni-users"></i></span> Follow Me</a>
                <a href="/cv.pdf" download target="_blank" rel="nofollow" className="main-btn wow fadeInLeftBig" data-wow-duration="1.3s" data-wow-delay="1.8s"><span className="h6 text-light mr-2"><i className="lni lni-download"></i></span> Download CV </a>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-6 col-10 pl-50">
              <div className="header-image ">
                <img src="/images/hero.png" alt="app" className="image wow fadeInRightBig" data-wow-duration="1.3s" data-wow-delay="1.5s" />
                <div className="icon-shape icon-shape-1">
                  <i className="lni lni-react"></i>
                </div>
                <div className="icon-shape icon-shape-2">
                  <i className="lni lni-laravel"></i>
                </div>
                <div className="icon-shape icon-shape-3">
                  <i className='bx bxl-tailwind-css'></i>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="header-shape-1"></div>
        <div className="s2  wow fadeInRightBig" data-wow-duration="1s" data-wow-delay="1s">
          <img src="/images/brush-3.png" alt="" />
        </div>
      </div>
    </header>
  );
}

export default Header;



