import React from 'react';
import SingleService from './SingleService';

const ServicesSection = () => {
    return (
        <section id="why" className="services-area pt-0">
            <div className="container">
                <div className="row">
                    <SingleService
                        iconClass="lni lni-layers"
                        colorClass="services-color-1"
                        title="Creative Design"
                        text="Always create creative and innovative designs and amazing UI/UX."
                        delay="0.2s"
                    />
                    <SingleService
                        iconClass="lni lni-layout"
                        colorClass="services-color-2"
                        title="Fast Response"
                        text="Has fast performance, response and processes and always provides the best"
                        delay="0.4s"
                    />
                    <SingleService
                        iconClass="lni lni-bolt"
                        colorClass="services-color-3"
                        title="High Quality"
                        text="Always produce and offer high quality in every project and work created"
                        delay="0.6s"
                    />
                    <SingleService
                        iconClass="lni lni-bulb"
                        colorClass="services-color-4"
                        title="Awesome Idea"
                        text="A brilliant idea in creating an amazing work that can impress people"
                        delay="0.8s"
                    />
                </div>
            </div>
        </section>
    );
};

export default ServicesSection;
