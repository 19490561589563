import React from 'react';

const Footer = () => {
    return (
        <footer id="footer" class="footer-area pt-100">        
            <div class="footer-shape shape-1"></div>
            <div class="footer-shape shape-2"></div>
            <div class="footer-shape shape-3"></div>
            <div class="footer-shape shape-4"></div>
            <div class="footer-shape shape-5"></div>
            <div class="footer-shape shape-6"></div>
            <div class="footer-shape shape-7"></div>
            
            <div class="footer-widget pt-30 pb-80" id="contact">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-4 col-md-6">
                            <div class="footer-about mt-50 wow fadeIn" data-wow-duration="1.3s" data-wow-delay="0.2s">
                                <a class="logo" href="#" >
                                    <img src="/images/logo.png" alt="Logo" />
                                </a>
                                <p class="text">I am Alvin Alvito & I like creating innovative work that connects ideas and reality. Let's start something amazing together!</p>
                                <ul class="social">
                                    <li><a href="#"><i class="lni lni-facebook"></i></a></li>
                                    <li><a href="#"><i class="lni lni-twitter"></i></a></li>
                                    <li><a href="#"><i class="lni lni-instagram"></i></a></li>
                                    <li><a href="#"><i class="lni lni-linkedin"></i></a></li>
                                </ul>
                            </div> 
                        </div> 
                        <div class="col-lg-5 col-md-6">
                            <div class="footer-link d-flex flex-wrap">
                                <div class="footer-link-wrapper mt-45 wow fadeIn" data-wow-duration="1.3s" data-wow-delay="0.4s">
                                    <div class="footer-title">
                                        <h4 class="title">Quick Links</h4>
                                    </div>
                                    <ul class="link">
                                        <li><a class="" href="#home">Home</a></li>
                                        <li><a class="" href="#about">About</a></li>
                                        <li><a class="" href="#portfolio">Portfolio</a></li>
                                        <li><a class="" href="#service">Service</a></li>
                                        <li><a class="" href="#project">Project</a></li>
                                    </ul>
                                </div> 
                                
                                <div class="footer-link-wrapper mt-45 wow fadeIn" data-wow-duration="1.3s" data-wow-delay="0.6s">
                                    <div class="footer-title">
                                        <h4 class="title">Support</h4>
                                    </div>
                                    <ul class="link">
                                        <li><a class="" href="#">Pustipada UINSU</a></li>
                                        <li><a class="" href="#">UIN Sumatera Utara</a></li>
                                        <li><a class="" href="#">LPM UINSU</a></li>
                                        <li><a class="" href="#">Ilmu Komputer UINSU</a></li>
                                        <li><a class="" href="#">SAINTEK UINSU</a></li>
                                    </ul>
                                </div>
                            </div> 
                        </div>
                        <div class="col-lg-3 col-md-6">
                            <div class="footer-contact mt-45 wow fadeIn" data-wow-duration="1.3s" data-wow-delay="0.8s">
                                <div class="footer-title">
                                    <h4 class="title">Contact</h4>
                                </div>
                                <ul class="contact-list">
                                    <li>
                                        <div class="contact-info d-flex">
                                            <div class="info-content media-body">
                                                 <p class="text"><a target="_blank" href="https://api.whatsapp.com/send/?phone=%2B6281260303320&text=Hallo%2C+Saya+ingin+berdiskusi+tentang+sebuah+inovasi+yang+saya+miliki&type=phone_number&app_absent=0"><i class="lni lni-phone"></i> +62 812-6030-3320</a></p>
                                            </div>
                                        </div> 
                                    </li>
                                    <li>
                                        <div class="contact-info d-flex">
                                            <div class="info-content media-body">
                                                <p class="text"><a target="_blank" href="mailto:alvinparis65@gmail.com"><i class="lni lni-envelope"></i> alvinparis65@gmail.com</a></p>
                                            </div>
                                        </div> 
                                    </li>
                                    <li>
                                        <div class="contact-info d-flex">
                                            <div class="info-content media-body">
                                                <p class="text"><a href="https://avinto.my.id"><i class="lni lni-world"></i> www.avinto.my.id</a></p>
                                            </div>
                                        </div> 
                                    </li>
                                    <li>
                                        <div class="contact-info d-flex">
                                            <div class="info-content media-body">
                                                <p class="text"><i class="lni lni-map"></i> Jl. Lap. Golf No.120, Kec. Pancur Batu, Kabupaten Deli Serdang, Sumatera Utara</p>
                                            </div>
                                        </div> 
                                    </li>
                                </ul>
                            </div> 
                        </div>
                    </div> 
                </div> 
            </div> 
            
            <div class="footer-copyright">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="copyright d-sm-flex justify-content-between">
                                <div class="copyright-text text-center">
                                    <p class="text">Powered by <a rel="nofollow" target="_blank" href="https://www.instagram.com/av_into?igsh=MTkydndxYXY2ZGFnMw==">Alvin Alvito</a></p>
                                </div> 
                                <div class="copyright-privacy text-center">
                                    <a className='text-primary ' target='_blank' href="https://react.dev/">React Js <span ><i className="lni lni-react"></i></span> </a>
                                </div>
                            </div> 
                            
                        </div>
                    </div> 
                </div> 
            </div> 
            <a href="#" class="scroll-top btn-hover">
                <i class="lni lni-chevron-up"></i>
            </a>
        </footer>
        
    );
};

export default Footer;
