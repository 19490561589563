import React, { useState, useEffect } from 'react';

const ProjectsSection = () => {
    const [activeFilter, setActiveFilter] = useState("all");

    useEffect(() => {
        const filterImg = document.querySelectorAll(".gallery .image");
        for (let i = 0; i < filterImg.length; i++) {
            filterImg[i].addEventListener("click", () => preview(filterImg[i]));
        }
        return () => {
            for (let i = 0; i < filterImg.length; i++) {
                filterImg[i].removeEventListener("click", () => preview(filterImg[i]));
            }
        };
    }, []);

    const filterImages = (selectedItem) => {
        const filterName = selectedItem.target.getAttribute("data-name");
        setActiveFilter(filterName);
        const filterImg = document.querySelectorAll(".gallery .image");
        filterImg.forEach((image) => {
            const filterImges = image.getAttribute("data-name");
            if (filterImges === filterName || filterName === "all") {
                image.classList.remove("hide");
                image.classList.add("show");
            } else {
                image.classList.add("hide");
                image.classList.remove("show");
            }
        });
    };

    const preview = (element) => {
        document.querySelector("body").style.overflow = "hidden";
        const selectedPrevImg = element.querySelector("img").src;
        const selectedImgCategory = element.getAttribute("data-name");
        const categoryName = document.querySelector(".preview-box .title p");
        const previewImg = document.querySelector(".preview-box img");
        previewImg.src = selectedPrevImg;
        categoryName.textContent = selectedImgCategory;
        document.querySelector(".preview-box").classList.add("show");
        document.querySelector(".shadow").classList.add("show");
        const closeIcon = document.querySelector(".preview-box .icon-close");
        closeIcon.onclick = () => {
            document.querySelector(".preview-box").classList.remove("show");
            document.querySelector(".shadow").classList.remove("show");
            document.querySelector("body").style.overflow = "auto";
        };
    };

    return (
        <section className="gallery">
            <div id="project" className="shape-2 wow fadeInRightBig" data-wow-duration="1s" data-wow-delay="1s">
                <img src="/images/brush-3.png" alt="" />
            </div>
            <div className="col-lg-12 pb-20">
                <div className="section-title text-center pb-25 wow fadeInRightBig" data-wow-duration="1s" data-wow-delay="1.5s">
                    <h3 className="title pb-10">My Projects</h3>                    
                    <p>These are projects I have completed using various technologies</p>                       
                </div> 
            </div>
            <div className="wrapper">     
                <div className="filter">
                    <div className="items" onClick={filterImages}>
                        <span className={`item ${activeFilter === 'all' ? 'active' : ''}`} data-name="all">All</span>
                        <span className={`item ${activeFilter === 'React' ? 'active' : ''}`} data-name="React">React</span>
                        <span className={`item ${activeFilter === 'Bootstrap' ? 'active' : ''}`} data-name="Bootstrap">Bootstrap</span>
                        <span className={`item ${activeFilter === 'Tailwind' ? 'active' : ''}`} data-name="Tailwind">Tailwind</span>
                        <span className={`item ${activeFilter === 'Laravel' ? 'active' : ''}`} data-name="Laravel">Laravel</span>
                        <span className={`item ${activeFilter === 'Wordpress' ? 'active' : ''}`} data-name="Wordpress">Wordpress</span>
                    </div>
                </div>
                <div className="gallery">
                    <div className="image" data-name="React">
                        <span><img src="/images/projects/portfolio-light.png" alt="" /></span>
                    </div>
                    <div className="image" data-name="Wordpress">
                        <span><img src="/images/projects/uinsu.png" alt="" /></span>
                    </div>
                    <div className="image" data-name="Laravel">
                        <span><img src="/images/projects/aipt.png" alt="" /></span>
                    </div>
                    <div className="image" data-name="Laravel">
                        <span><img src="/images/projects/career.png" alt="" /></span>
                    </div>
                    <div className="image" data-name="Bootstrap">
                        <span><img src="/images/projects/clear.png" alt="" /></span>
                    </div>
                    <div className="image" data-name="Bootstrap">
                        <span><img src="/images/projects/pusdikra.png" alt="" /></span>
                    </div>
                    <div className="image" data-name="Laravel">
                        <span><img src="/images/projects/tracer.png" alt="" /></span>
                    </div>
                    <div className="image" data-name="Tailwind">
                        <span><img src="/images/projects/portfolio-night.png" alt="" /></span>
                    </div>
                </div>
            </div>
            <div className="preview-box">
                <div className="details">
                    <span className="title">Project Category: <p></p></span>
                    <span className="icon-close"><i className="bi bi-x"></i></span>
                </div>
                <div className="image-box"><img src="" alt="" /></div>
            </div>
            <div className="shadow"></div>
        </section>
    );
};

export default ProjectsSection;
