import React from 'react';

const AboutSection = () => {
    return (
        <section id="about" className="about-area pt-100">
            <div className="shape-2">
                <img src="/images/s4.png" alt="" />
            </div>
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-7">
                        <div className="section-title text-center pb-25 wow fadeInLeftBig" data-wow-duration="1.3s" data-wow-delay="0.5s">
                            <h3 className="title">About Me</h3>
                            <p className="text">I am a programmer who has several skills in the IT field. Of the many skills I have, this skill is the most prominent, among others</p>
                        </div>
                    </div>
                </div>
                <div className="row align-items-center">
                    <div className="col-lg-6">
                        <div className="about-image mt-50 wow fadeInLeftBig" data-wow-duration="1.3s" data-wow-delay="0.5s">
                            <div className="about-shape"></div>
                            <img className="app" src="images/content-3.png" alt="app" />
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="features-items">
                            <div className="single-features features-color-1 d-sm-flex mt-30 wow fadeInUpBig" data-wow-duration="1.3s" data-wow-delay="0.2s">
                                <div className="features-icon d-flex justify-content-center align-items-center">
                                    <i className="lni lni-laptop-phone"></i>
                                </div>
                                <div className="features-content media-body">
                                    <h5 className="features-title">UI/UX Designer</h5>
                                    <p className="text">In designing UI/UX, I have skills in using Figma and several mobile editor applications</p>
                                </div>
                            </div>
                            <div className="single-features features-color-2 d-sm-flex mt-30 wow fadeInUpBig" data-wow-duration="1.3s" data-wow-delay="0.4s">
                                <div className="features-icon d-flex justify-content-center align-items-center">
                                    <i className="lni lni-code-alt"></i>
                                </div>
                                <div className="features-content media-body">
                                    <h5 className="features-title">Web Developer</h5>
                                    <p className="text">I have advantages in the front end with skills in using: React Js, Tailwind, Bootstrap & Jquery. Then in Backend I can use: Laravel & php native</p>
                                </div>
                            </div>
                            <div className="single-features features-color-3 d-sm-flex mt-30 wow fadeInUpBig" data-wow-duration="1.3s" data-wow-delay="0.6s">
                                <div className="features-icon d-flex justify-content-center align-items-center">
                                    <i className="lni lni-mobile"></i>
                                </div>
                                <div className="features-content media-body">
                                    <h5 className="features-title">Mobile Developer</h5>
                                    <p className="text">I use Flutter to create a mobile application, then the backend uses Laravel</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default AboutSection;
