import React, { useEffect } from 'react';
import { tns } from 'tiny-slider/src/tiny-slider';

const ServiceAndPricingSection = () => {
    useEffect(() => {
        const slider = tns({
            container: '.pricing-active',
            autoplay: false,
            mouseDrag: true,
            gutter: 0,
            nav: false,
            controls: true,
            controlsText: [
                '<i class="lni lni-chevron-left prev"></i>',
                '<i class="lni lni-chevron-right prev"></i>',
            ],
            responsive: {
                0: {
                    items: 1,
                },
                768: {
                    items: 2,
                },
                992: {
                    items: 1.2,
                },
                1200: {
                    items: 2,
                }
            }
        });
        
        return () => {
            slider.destroy();
        };
    }, []);

    return (
        <section id="pricing" className="pricing-section pricing-style-4">       
            <div className="container" id="service">
                <div className="shape-1">
                    <img src="/images/s6.png" alt="" />
                </div>
                <div className="row align-items-center">
                    <div className="col-xl-5 col-lg-6">
                        <div className="section-title mb-60">
                            <h3 className="mb-15 wow fadeInUp" data-wow-delay=".2s">Service & Pricing</h3>               
                            <p className="wow fadeInUp" data-wow-delay=".4s">We provide a wide range of digital services like UI/UX Design, Front end Development, Web Development, Mobile Development, IT Support. With affordable prices, fast processing and the best service. We are ready to realize your best ideas in developing business and marketing or managing resources</p>
                        </div>
                    </div>
                    <div className="col-xl-7 col-lg-6">
                        <div className="pricing-active-wrapper wow fadeInUp" data-wow-delay=".4s">
                            <div className="pricing-active">
                                <div className="single-pricing-wrapper">
                                    <div className="single-pricing">
                                        <h4>UI/UX Design</h4>
                                        <h6>Start From</h6>
                                        <h3>100K</h3>
                                        <ul>
                                            <li>User-friendly interfaces</li>
                                            <li>Modern design concepts with figma</li>
                                            <li>Interactive user experiences</li>
                                            <li>Optimized user workflows</li>
                                        </ul>
                                        <a target="_blank" href="https://api.whatsapp.com/send/?phone=%2B6281260303320&text=Hallo%2C+Saya+ingin+berdiskusi+tentang+sebuah+inovasi+yang+saya+miliki&type=phone_number&app_absent=0" className="button radius-30">Get Started</a>
                                    </div>
                                </div>                                           
                                <div className="single-pricing-wrapper">
                                    <div className="single-pricing">
                                        <h4>Front-end Development</h4>
                                        <h6>Start From</h6>
                                        <h3>200K</h3>
                                        <ul>
                                            <li>Latest front end tech</li>
                                            <li>Responsive web design</li>
                                            <li>Cross-browser compatibility</li>
                                            <li>Scalable web applications</li>
                                        </ul>
                                        <a target="_blank" href="https://api.whatsapp.com/send/?phone=%2B6281260303320&text=Hallo%2C+Saya+ingin+berdiskusi+tentang+sebuah+inovasi+yang+saya+miliki&type=phone_number&app_absent=0" className="button radius-30">Get Started</a>
                                    </div>
                                </div>
                                <div className="single-pricing-wrapper">
                                    <div className="single-pricing">
                                        <h4>Web Development</h4>
                                        <h6>Start From</h6>
                                        <h3>500K</h3>
                                        <ul>
                                            <li>Customized web solutions</li>
                                            <li>Backend integration</li>
                                            <li>Content management systems</li>
                                            <li>E-commerce platforms</li>
                                        </ul>
                                        <a target="_blank" href="https://api.whatsapp.com/send/?phone=%2B6281260303320&text=Hallo%2C+Saya+ingin+berdiskusi+tentang+sebuah+inovasi+yang+saya+miliki&type=phone_number&app_absent=0" className="button radius-30">Get Started</a>
                                    </div>
                                </div>
                                <div className="single-pricing-wrapper">
                                    <div className="single-pricing">
                                        <h4>Mobile Development</h4>
                                        <h6>Start From</h6>
                                        <h3>400K</h3>
                                        <ul>
                                            <li>Native and cross-platform apps</li>
                                            <li>Mobile-responsive designs</li>
                                            <li>App store optimization</li>
                                            <li>Secure data handling</li>
                                        </ul>
                                        <a target="_blank" href="https://api.whatsapp.com/send/?phone=%2B6281260303320&text=Hallo%2C+Saya+ingin+berdiskusi+tentang+sebuah+inovasi+yang+saya+miliki&type=phone_number&app_absent=0" className="button radius-30">Get Started</a>
                                    </div>
                                </div>
                                <div className="single-pricing-wrapper">
                                    <div className="single-pricing">
                                        <h4>IT Support</h4>
                                        <h6>Start From</h6>
                                        <h3>300K</h3>
                                        <ul>
                                            <li>24/7 tech support</li>
                                            <li>Network infrastructure management</li>
                                            <li>Security protocols</li>
                                            <li>Remote troubleshooting</li>
                                        </ul>
                                        <a target="_blank" href="https://api.whatsapp.com/send/?phone=%2B6281260303320&text=Hallo%2C+Saya+ingin+berdiskusi+tentang+sebuah+inovasi+yang+saya+miliki&type=phone_number&app_absent=0" className="button radius-30">Get Started</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default ServiceAndPricingSection;
