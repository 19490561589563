import React from 'react';

const SingleService = ({ iconClass, colorClass, title, text, delay }) => {


    return (
        <div className="col-lg-3 col-sm-6">
            <div className={`single-services text-center mt-30 wow fadeInUpBig ${colorClass}`} data-wow-duration="1.3s" data-wow-delay={delay}>
                <div className="services-icon d-flex align-items-center justify-content-center">
                    <i className={iconClass}></i>
                </div>
                <div className="services-content">
                    <h4 className="services-title"><a href="#">{title}</a></h4>
                    <p className="text">{text}</p>
                </div>
            </div>
        </div>
    );
};

export default SingleService;
