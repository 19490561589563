import React, { useEffect, useState } from 'react';
import '../assets/css/style.css';

const Loader = () => {
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setIsLoaded(true);
      document.body.classList.add("loaded"); 
    }, 1000);

    return () => clearTimeout(timeout);
  }, []);

  return (
    <div className={`loader-wrapper`}> 
      <div className="loader"></div>
      <div className="loader-section section-left"></div>
      <div className="loader-section section-right"></div>
    </div>
  );
};

export default Loader;
