import React, { useEffect } from 'react';
import Loader from './components/Loader';
import Header from './components/Header';
import ServicesSection from './components/ServicesSection';
import AboutSection from './components/AboutSection';
import PortfolioSection from './components/PortfolioSection';
import ProfessionalCareerSection from './components/ProfessionalCareerSection';
import ServiceAndPricingSection from './components/ServiceAndPricingSection';
import ProjectsSection from './components/ProjectsSection';
import HighlightSection from './components/HighlightSection ';
import Footer from './components/Footer';
import './App.css';

import './assets/css/animate.css';
import './assets/css/lineicons.css';
import 'boxicons/css/boxicons.min.css';
import './assets/css/bootstrap.min.css';
import './assets/css/default.css';
import './assets/css/swiper-bundle.min.css';
import './assets/css/style.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import './assets/css/tiny-slider.css';
import './assets/css/lindy-uikit.css';

// Import WOW.js
import WOW from 'wowjs';

function App() {
  useEffect(() => {
    new WOW.WOW().init();
  }, []); 

  return (
    <div className="App">
      <Loader />
      <Header />
      <ServicesSection />
      <AboutSection />
      <PortfolioSection />
      <ProfessionalCareerSection />
      <ServiceAndPricingSection />
      <ProjectsSection />
      <HighlightSection />
      <Footer />
    </div>
  );
}

export default App;
