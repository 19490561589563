import React from 'react';

const PortfolioSection = () => {
    return (
        <section id="download1" className="download-area pt-100">
            <div className="shape-1">
                <img src="/images/brush-2.png" alt="" />
            </div>
            <div className="container" id="portfolio">
                <div className="row justify-content-center">
                    <div className="col-lg-7">
                        <div className="section-title text-center wow fadeInLeftBig" data-wow-duration="1.3s" data-wow-delay="0.5s">
                            <h3 className="title">Portfolio</h3>
                            <p className="text">This is a portfolio summary about My Biography, Education & Work Ecperience </p>
                        </div>
                    </div>
                </div>
                <div className="row justify-content-center align-items-center">
                    <div className="col-lg-6">
                        <div className="download-content mt-45 wow fadeInLeftBig" data-wow-duration="1.3s" data-wow-delay="0.5s">
                            <h3 className="download-title">Biography & Education</h3>
                            <p className="text">Born in Bandung on May 5 2003, then grew up in Medan from the age of 7 years. Having been interested in the world of programming since the age of 16, then in high school he majored in computer network engineering at SMKS Imelda Medan North Sumatera, in 2021 after graduating he continued his education at the North Sumatra State Islamic University (UINSU) until now and this is my 3rd year of college</p>
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-9 pl-50">
                        <div className="download-image mt-50 wow fadeInRightBig" data-wow-duration="1.3s" data-wow-delay="0.2s">
                            <img className="image" src="images/content-1.png" alt="download" />
                            <div className="download-shape-1"></div>
                            <div className="download-shape-2">
                                <img className="svg" src="images/download-shape.svg" alt="shape" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default PortfolioSection;
