import React, { useEffect } from 'react';
import Swiper from 'swiper';
import { Navigation, Pagination, Autoplay } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

Swiper.use([Navigation, Pagination, Autoplay]);

const HighlightSection = () => {
    useEffect(() => {
        const swiper = new Swiper('.mySwiper', {
            slidesPerView: 1,
            grabCursor: true,
            loop: true,
            autoplay: {
                delay: 4000, 
                disableOnInteraction: false
            },
            navigation: {
                nextEl: ".swiper-button-next",
                prevEl: ".swiper-button-prev",
            },
            pagination: {
                el: ".swiper-pagination",
                clickable: true,
            },
        });

        return () => {
            swiper.destroy();
        };
    }, []);
    return (
        <section className="container-testi pt-100">  
            <div className="shape-1  wow fadeInRightBig" data-wow-duration="1s" data-wow-delay="1s">
                <img src="/images/brush-2.png" alt="" />
            </div>      
            <div className="testimonial mySwiper">
                <div className="row justify-content-center">
                    <div className="col-lg-12 pb-20">
                        <div className="section-title text-center pb-25">
                            <h3 className="title pb-10">Highlight & Testimony</h3> 
                            <p>Below are people's latest highlights & testimonials about me</p>                       
                        </div> 
                    </div>
                </div> 
                <div className="testi-content swiper-wrapper">
                    <div className="slide swiper-slide">
                        <img src="/images/hightight/pak-isan.jpg" alt="" className="image" />
                        <p>You've demonstrated impressive skills in front-end development and UI/UX design. Your performance on our team at Pustipada has been amazing. I highly appreciate the dedication and innovation you bring to our projects. I'm proud to work with you.</p>
                        <i className="bx bxs-quote-alt-left quote-icon"></i>
                        <div className="details">
                            <span className="name">Dr. Muhammad Ikhsan, ST., M.Kom</span>
                            <span className="job">Pustipada leadership/Lecturer/Project manager</span>
                        </div>
                    </div>
                    <div className="slide swiper-slide">
                            <img src="/images/hightight/pak-ibnu.png" alt="" className="image" />
                            <p>
                                As a web developer, you've proven your quality in front-end development and UI/UX design. Your work at Pustipada is outstanding, and I'm highly impressed by your dedication in producing remarkable works.
                            </p>
                            <i className="bx bxs-quote-alt-left quote-icon"></i>
                            <div className="details">
                                <span className="name">Ibnu Rusydi, M.Kom</span>
                                <span className="job">Database Administrator/Lecturer/Staff Pustipada</span>
                            </div>
                    </div>
                    <div className="slide swiper-slide">
                            <img src="/images/hightight/pak-ayar.jpg" alt="" className="image" />
                            <p>
                                Your performance as a student who also works as a staff member at Pustipada in the field of front-end development and UI/UX design is truly remarkable. You're also a pleasant and friendly individual. You've brought valuable contributions to our team with your creativity and technical skills.
                            </p>
                            <i className="bx bxs-quote-alt-left quote-icon"></i>
                            <div className="details">
                                <span className="name">Akhyar Aslam Sipahutar, S.T</span>
                                <span className="job">Database Administrator/Staff Pustipada</span>
                            </div>
                    </div>
                    <div className="slide swiper-slide">
                            <img src="/images/hightight/pak-jamal.jpg" alt="" className="image" />
                            <p>
                                As a student, you've shown exceptional abilities in front-end development and UI/UX design. Your contribution at Pustipada is highly meaningful to our projects.
                            </p>
                            <i className="bx bxs-quote-alt-left quote-icon"></i>
                            <div className="details">
                                <span className="name">Ahmad Rizal, M.Kom</span>
                                <span className="job">Software Engineer/Lecturer/Staff Pustipada</span>
                            </div>
                    </div>
                
                </div>
                <div className="swiper-button-next nav-btn"></div>
                <div className="swiper-button-prev nav-btn"></div>
                <div className="swiper-pagination"></div>
            </div>
        </section>
    );
};

export default HighlightSection;
