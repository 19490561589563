import React from 'react';

const ProfessionalCareerSection = () => {
    return (
        <section id="download2" className="download-area pt-100">
            <div className="shape-2">
                <img src="/images/brush.png" alt="" />
            </div>
            <div className="container">
                <div className="row justify-content-center align-items-center">
                    <div className="col-lg-6 col-md-9">
                        <div className="download-image mt-50 wow fadeInRightBig" data-wow-duration="1.3s" data-wow-delay="0.2s">
                            <img className="image" src="/images/content-2.png" alt="download" />
                            <div className="download-shape-1"></div>
                            <div className="download-shape-2">
                                <img className="svg" src="/images/download-shape.svg" alt="shape" />
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="download-content mt-45 wow fadeInLeftBig" data-wow-duration="1.3s" data-wow-delay="0.5s">
                            <h3 className="download-title">Work Experience</h3>
                            <p className="text">Starting a professional career in 2022, with basic network penetration skills. then started to dive into the world of programming as a web developer and focused on being a UI/UX and front end developer. Then he worked as a freelance to form a team and opened a website creation and IT support service. In 2023, for 2 semesters working as a computer science laboratory assistant at UINSU. Then in 2024 he was invited to become Pustipada's staff as a Software Development in charge of handling applications and websites for the North Sumatra State Islamic University (UINSU) and is still active today.</p>
                            <ul>
                                <li>
                                    <a className="app-store text-light" href="#">
                                    <span className="h4 text-light m-2"><i className="lni lni-rocket"></i></span> 30+ TOTAL PROJECTS</a>
                                </li>
                                <li>
                                    <a className="play-store text-light" href="#">
                                    <span className="h4 text-light m-2"><i className="lni lni-bolt"></i></span> 2+ YEARS EXPERIENCE</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default ProfessionalCareerSection;
